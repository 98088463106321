import Utility from "../../../shared/utility";
import EncryptUtility from "../../utility/js/encrypt-utility";
import breadcrumbComp from "../../common/breadcrumb-comp";
import AddFavourite from "../../utility/js/addFav";
import BackToTop from "../../common/back-to-top.vue";
export default {
  data() {
    return {
      showEdit: false,
      showDelete: false,
      saveRole:false,
      name: "RoleUpdate",
      max50Rule: [(v) => (v || "").length <= 50 || "Max Length of 50 character"],
      max500Rule: [(v) => (v || "").length <= 500 || "Max Length of 500 character"],
      roleMenuData: [],
      userId: EncryptUtility.localStorageDecrypt("userID"),
      roleIdData: [],
      allIds: [],
      selectedIds:[],
      selectedRole: [],
      tempSelectedRole:[],
      notSelectedArray:[],
      mainPage: "",
      backSlash: true,
      backSlashParent: true,
      subPage: "",
      subPageUrl: "",
      pageUrl: "",
      appPageName: "",
      allowedEdit: false,
      allowProject: false,
      allowBusiness: false,
      deleteRoleDialog: false,
      roleData: [],
      roleMenuDisableData: [],
      roleId: "",
      selectedInstaller: "",
      InstallId: "2",
      roleName: "",
      allowInstaller: false,
      txtDescription: true,
      role: true,
      list: [
        { name: "Menu Selection", active: false },
        { name: "Project and Business Selection", active: false },
      ],
      projectLists: [],
      selProjects: [],
      disableTreeModel:[],
      selBusiness: [],
      businessLists: [],
      allProjectSelect: false,
      allBusinessSelect: false,
      allProjects: [],
      finalSelList: [],
      actionList: [],
      isActive: 1,
      projectSelected: true,
      showUserListBtn: false,
      roleStatus: "",
      assignedUsersListDialog: false,
      projectBusValid: false,
      projectWithNoBus: [],
      parentSelected: false,
      assignedUserHeaders: [
        { text: "Name", align: "start", value: "Name" },
        { text: "Email", value: "Email" },
      ],
      assignedUserItems: [],
      editPageDetails: JSON.parse(EncryptUtility.localStorageDecrypt("EditPageDetails")),
      roleChildData: [],
    };
  },
  async created() {
    this.route = this.$route.path.replace("/", "").split("/")[0];
    let data = await AddFavourite.getParentPageAction(this.userId, this.route);
    this.actionList = data.actionList;
    this.mainPage = data.mainPage;
    this.favouriteList = data.favouriteList;
    this.pageUrl = data.pageUrl;
    this.subPage = data.subPage;
    this.showAdd = data.showAdd;
    this.subPageUrl = data.subPageUrl;
    this.appPageName = data.appPageName;
    this.showEdit = this.editPageDetails["editRole"];
    this.showDelete = this.editPageDetails["deleteRole"] && (this.editPageDetails["roleUsed"] == 0 ? true : false);
  //  this.getRoleMenuData();
  this.getEditItem(this.roleId);
  },
  mounted() {
    //get User Id from URL
    this.roleId = this.editPageDetails["roleId"];
    this.selectedInstaller = this.editPageDetails["selectedInstaller"];
    this.description = this.editPageDetails["description"];
    this.description = this.description == "undefined" ? "" : this.description;
    this.roleName = this.editPageDetails["roleName"];
    this.roleStatus = this.editPageDetails["status"];
    if (this.roleStatus == "Inactive") {
      this.statusShow = true;
      this.status = false;
    } else {
      this.statusShow = false;
      this.status = true;
    }

    this.getProjectList();
    this.getBusinessList();
    this.userListAssigned();
  },

 
  methods: {
    //scrolling into respective group
    onClickGroup(obj) {
      const element = document.getElementById(obj.name);
      element.scrollIntoView();
      document.documentElement.scrollTop = 0;
    },
    //Click event for Assigned User button
    userList() {
      this.userListAssigned();
      this.assignedUsersListDialog = true;
    },
    // getRoleMenuData() {
    //   let LoaderDialog = {
    //     visible: true,
    //     title: "Please Wait...",
    //   };
    //   this.axios
    //     .get("ap/rolemenu_list?UserId=" + this.userId)
    //     .then((response) => {
    //       if (response.status == 200) {
    //         let responseData = JSON.parse(response.data.body.message);
    //         console.log("getMenuID",responseData)
    //         let tempData = responseData;
    //         this.roleMenuData = responseData;
    //         this.roleMenuDisableData = tempData;
    //         console.log(this.roleMenuDisableData)
    //         console.log(this.roleMenuData)
    //         for (let i = 0; i < responseData.length; i++) {
    //           this.roleMenuDisableData[i]["locked"] = true;
    //           this.openIds.push(responseData[i].id);
    //           if (responseData[i]?.children) {
    //             for (let j = 0; j < responseData[i]?.children.length; j++) {
    //               this.roleMenuDisableData[i].children[j]["locked"] = true;
    //               this.openIds.push(responseData[i]?.children[j].id);
    //               if (responseData[i]?.children[j]?.children) {
    //                 for (let k = 0; k < responseData[i]?.children[j]?.children?.length; k++) {
    //                   this.roleMenuDisableData[i].children[j].children[k]["locked"] = true;
    //                   this.openIds.push(responseData[i]?.children[j]?.children[k]?.id);
    //                   if (responseData[i]?.children[j]?.children[k]?.children) {
    //                     for (let l = 0; l < responseData[i]?.children[j]?.children[k]?.children?.length; l++) {
    //                       this.roleMenuDisableData[i].children[j].children[k].children[l]["locked"] = true;
    //                       this.openIds.push(responseData[i]?.children[j]?.children[k]?.children[l]?.id);
    //                       if (responseData[i]?.children[j]?.children[k]?.children[l]?.children) {
    //                         for (let m = 0; m < responseData[i]?.children[j]?.children[k]?.children[l]?.children?.length; m++) {
    //                           if (responseData[i]?.children[j]?.children[k]?.children[l]?.children[m]?.id)
    //                             this.roleMenuDisableData[i].children[j].children[k].children[l].children[m]["locked"] = true;
    //                           this.openIds.push(responseData[i]?.children[j]?.children[k]?.children[l]?.children[m].id);
    //                         }
    //                       }
    //                     }
    //                   }
    //                 }
    //               }
    //             }
    //           }
    //         }
    //         console.log("total income",162,this.openIds)
    //         if (this.roleId) {
    //           this.getEditItem(this.roleId);
    //         }
    //         LoaderDialog.visible = false;
    //         this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    //       } else {
    //         LoaderDialog.visible = false;
    //         this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    //         var Alert = {
    //           type: "error",
    //           isShow: true,
    //           message: response.data.message,
    //         };
    //         this.$store.commit("ConfigModule/Alert", Alert);
    //       }
    //       LoaderDialog.visible = false;
    //       this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    //     })
    //     .catch((error) => {
    //       LoaderDialog.visible = false;
    //       this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
    //       var Alert = {
    //         type: "error",
    //         isShow: true,
    //         message: Utility.apiError(error),
    //       };
    //       this.$store.commit("ConfigModule/Alert", Alert);
    //       this.errorMessage = error.message;
    //     });
    // },
    // To check if the user has access access to that action or not
    actionSetter(action) {
      if (this.actionList !== null) {
        let filter = this.actionList.filter((entry) => entry.SystemName === action);
        return filter !== undefined && filter !== null && filter.length == 1 ? true : false;
      } else return false;
    },

    //Edit Role
    getEditItem(roleId) {
      this.roleIdData = [];
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("ap/rolemenu_list?roleid=" + roleId + "&UserId=" + this.userId)
        .then((response) => {
          if (response.status == 200) {
            this.roleMenuData= JSON.parse(response.data.body.message)
            this.getAllIds()
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },

    //Update role
    updateRole() {
      this.getSelectedIds()
      this.selProjects = [...new Set(this.selProjects)];
      this.selBusiness = this.selBusiness.filter((obj) => this.selProjects.includes(obj.ProjectId));
   //   this.selProjects = this.businessLists.map((item) => item.ProjectId);
      if (this.selProjects.length !== 0) {
        if (this.selBusiness.length !== 0) {
          this.projectBusValid = this.selProjects.every((projectId) => this.selBusiness.some((project) => project.ProjectId === projectId));
          if (this.projectBusValid) {
            this.selectedValues = [];
            this.finalSelList = [];
            this.finalSelList = this.selBusiness.map((obj) => {
              return {
                ProjectId: obj.ProjectId,
                BusinessId: obj.BusinessId,
                IsActive: this.isActive,
              };
            });
            // Change CreatedBy as well
            let LoaderDialog = {
              visible: true,
              title: "Please Wait...",
            };
            var allProject = this.allProjectSelect ? 1 : 0;
            var allBusiness = this.allBusinessSelect ? 1 : 0;
            let rpbObject = JSON.stringify({
              isAllProjects: parseInt(allProject),
              isAllBusiness: parseInt(allBusiness),
              RPBInfo: this.finalSelList,
            });
            let upsertdata = {
              RoleId: this.roleId,
              RoleName: this.roleName,
              Description: this.description,
              InstallId: this.selectedInstaller,
              CreatedBy: this.userId,
              MenuIds: this.selectedIds,
              Isactive: this.status,
              RPBJson: rpbObject,
            };
            this.axios
              .post("/ap/role_upsert", upsertdata)
              .then((response) => {
                if (response.status == 200 || response.status == 204) {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "success",
                    isShow: true,
                    message: response.data.body.message,
                  };
                  this.isDisabled = true;
                  this.allowedEdit = false;
                  this.modaldialog = false;
                  this.selectedValues = [];
                  this.$store.commit("ConfigModule/Alert", Alert);
                  this.$router.push("/app-role");
                } else {
                  LoaderDialog.visible = false;
                  this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                  let Alert = {
                    type: "error",
                    isShow: true,
                    message: response.data.message,
                  };
                  this.isDisabled = true;
                  this.allowedEdit = false;
                  this.modaldialog = false;
                  this.selectedValues = [];
                  this.$store.commit("ConfigModule/Alert", Alert);
                }
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
              })
              .catch((error) => {
                LoaderDialog.visible = false;
                this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
                let Alert = {
                  type: "error",
                  isShow: true,
                  message: Utility.apiError(error),
                };
                this.$store.commit("ConfigModule/Alert", Alert);
                this.modaldialog = false;
                this.$store.commit("ConfigModule/Alert", Alert);
                this.errorMessage = error.message;
                this.selectedValues = [];
              });
          } else {
            let Alert = {
              type: "error",
              isShow: true,
              message: "Please select at least one Business for selected Project(s)",
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        } else {
          let Alert = {
            type: "error",
            isShow: true,
            message: "Please select any Business",
          };
          this.$store.commit("ConfigModule/Alert", Alert);
        }
      } else {
        let Alert = {
          type: "error",
          isShow: true,
          message: "Please select any Project",
        };
        this.$store.commit("ConfigModule/Alert", Alert);
      }
    },
    toggleChildren(item, state) {
      if (item.children) {
        item.children.forEach(child => {
          child.selected = state;
          this.toggleChildren(child, state);
        });
      }
      this.updateParentState(item);
    },
    updateParentState(item) {
      let parent = this.findParent(this.roleMenuData, item);
      while (parent) {
        if (parent.children.every(child => !child.selected)) {
          parent.selected = false;
        }
        parent = this.findParent(this.roleMenuData, parent);
      }
    },
    getSelectedIds() {
      this.selectedIds = [];
      this.collectSelectedIds(this.roleMenuData, this.selectedIds);
    },
    collectSelectedIds(items, selectedIds) {
      items.forEach(item => {
        if (item.selected) {
          selectedIds.push(item.id);
        }
        if (item.children) {
          this.collectSelectedIds(item.children, selectedIds);
        }
      });
    },
  
    isParentDisabled(item) {
      let parent = this.findParent(this.roleMenuData, item);
      while (parent) {
        if (!parent.selected) {
          return true;
        }
        parent = this.findParent(this.roleMenuData, parent);
      }
      return false;
    },
    findParent(items, child) {
      for (const item of items) {
        if (item.children && item.children.includes(child)) {
          return item;
        } else if (item.children) {
          const found = this.findParent(item.children, child);
          if (found) return found;
        }
      }
      return null;
    },
    getAllIds(){
      this.allIds=[]
    this.collectAllIds(this.roleMenuData, this.allIds);
  },
  collectAllIds(items, allIds) {
    items.forEach(item => {
      allIds.push(item.id);
      if (item.children) {
        this.collectAllIds(item.children, allIds);
      }})},

    //allow to Edit
    allowEdit() {
      this.saveRole=true;
      this.isDisabled = false;
      this.txtDescription = false;
      this.role = true;
      this.allowedEdit = true;
      this.allowBusiness = true;
      this.allowProject = true;
    },
    //Open delete modal popup
    allowDelete() {
      this.deleteRoleDialog = true;
    },
    editClick() {
      this.allowEdit();
    },
    deleteClick() {
      this.allowDelete();
    },
    //Get Page Action
    getPageAction(url, userId) {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`/ap/user_page_actions?user_id=${userId}&page_url=${url}`)
        .then((response) => {
          if (response.status == 200) {
            let responsedata = [];
            responsedata = JSON.parse(response.data.body.message);
            this.actionList = responsedata.Action;
            this.mainPage = responsedata.GrandParentName;
            this.subPage = responsedata.ParentName;
            this.subPageUrl = responsedata.ParentUrl;
            this.pageUrl = responsedata.PageUrl;
            this.appPageName = responsedata.PageName;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //cancel the delete modal popup
    onClickCancel() {
      this.deleteRoleDialog = false;
      this.allowedEdit = false;
    },
    //Delete Role
    deleteRole() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      let groupdata = {
        RoleId: this.roleId,
        UserId: this.userId,
      };
      this.axios
        .post("/ap/role_delete", groupdata)
        .then((response) => {
          if (response.status == 200 || response.status == 204) {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "success",
              isShow: true,
              message: response.data.body.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
            this.deleteRoleDialog = false;
            this.modaldialog = false;
            this.$router.push("/app-role");
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            let Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.deleteRoleDialog = false;
            this.modaldialog = false;
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          let Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.deleteRoleDialog = false;
          this.modaldialog = false;
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Validate Role Name
    roleValid() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("ap/role_validate?user_id=" + this.userId + "&rolename=" + this.roleName + "&installid=" + this.InstallId)
        .then((response) => {
          if (response.status == 200) {
            this.txtDescription = false;
            this.role = true;
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Refresh Function
    reset() {
      this.$refs.editRoleForm.resetValidation();
      this.txtDescription = true;
      this.role = true;
      this.allowedEdit = false;
      this.allowBusiness = false;
      this.allowProject = false;
      this.selBusiness = [];
      this.selProjects = [];
      this.projectLists = [];
      this.businessLists = [];
      this.finalSelList = [];
      this.getProjectList();
      this.getBusinessList();
      this.getEditItem(this.roleId);
      this.roleId = atob(this.$route.params.roleId);
      this.selectedInstaller = parseInt(atob(this.$route.params.selectedInstaller));
      this.description = atob(this.$route.params.description);
      this.roleName = atob(this.$route.params.roleName);
      this.roleStatus = atob(this.$route.params.status);
      this.deleteRoleDialog = false;
    },
    //Get Project List
    getProjectList() {
      this.projectLists = [];
      this.allProjectSelect = false;
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get("ap/role_projectlist?User_Id=" + this.userId + "&Role_Id=" + this.roleId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            const newArr = responseData.map((obj) => {
              return {
                ...obj,
              };
            });
            this.projectLists = newArr;
            for (var i = 0; i < this.projectLists.length; i++) {
              this.allProjects.push(this.projectLists[i].ProjKey);
              if (this.projectLists[i].Selected == true) {
                this.selProjects.push(this.projectLists[i].ProjKey);
                if (this.selProjects.length == this.projectLists.length) {
                  this.allProjectSelect = true;
                }
              }
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Get Business List
    getBusinessList(ProjKeyList) {
      this.businessLists = [];
      this.allBusinessSelect = false;
      this.axios
        .get("ap/role_businesslist?User_Id=" + this.userId + "&Role_Id=" + this.roleId)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (ProjKeyList == null) {
              this.businessLists = responseData;
              for (var j = 0; j < responseData.length; j++) {
                if (responseData[j].Selected == true) {
                  this.selBusiness.push(responseData[j]);
                  if (this.selBusiness.length == responseData.length) {
                    this.allBusinessSelect = true;
                  } else {
                    this.allBusinessSelect = false;
                  }
                }
              }
            } else {
              for (var i = 0; i < ProjKeyList.length; i++) {
                for (var key = 0; key < responseData.length; key++) {
                  if (ProjKeyList[i] == responseData[key].ProjectId) {
                    this.businessLists.push(responseData[key]);
                  }
                }
              }
              if (this.selBusiness.length == this.businessLists.length) {
                this.allBusinessSelect = true;
              }
              this.businessLists = [...new Set(this.businessLists)];
            }
          } else {
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
        })
        .catch((error) => {
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Selected Project
    projectSel() {
      this.projectSelected = true;
      if (this.selProjects.length !== 0) {
        if (this.projectLists.length !== this.selProjects.length) {
          this.allProjectSelect = false;
          this.getBusinessList(this.selProjects);
        } else if (this.projectLists.length == this.selProjects.length) {
          this.allProjectSelect = true;
          this.getBusinessList(this.selProjects);
        }
      } else {
        this.allProjectSelect = false;
        this.businessLists = [];
        this.projectSelected = false;
      }
    },
    //Business Selected
    businessSel() {
      if (this.selBusiness.length == this.businessLists.length) {
        this.allBusinessSelect = true;
      } else {
        this.allBusinessSelect = false;
      }
    },
    //Select all projects
    allProjectSel() {
      if (this.allProjectSelect == true) {
        this.projectSelected = true;
        for (var i = 0; i < this.projectLists.length; i++) {
          this.selProjects.push(this.projectLists[i].ProjKey);
        }
        this.getBusinessList(this.selProjects);
      } else {
        this.selProjects = [];
        this.businessLists = [];
        this.projectSelected = false;
        this.allProjectSelect = false;
      }
    },
    //Select all Business
    allBusinessSel() {
      if (this.allBusinessSelect == true) {
        for (var i = 0; i < this.businessLists.length; i++) {
          this.selBusiness.push(this.businessLists[i]);
        }
      } else {
        this.selBusiness = [];
        this.allBusinessSelect = false;
      }
    },
    //Assigned user list API
    userListAssigned() {
      let LoaderDialog = {
        visible: true,
        title: "Please Wait...",
      };
      this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
      this.axios
        .get(`co/user_list_by_roleid?UserId=${this.userId}&role_id=${this.roleId}`)
        .then((response) => {
          if (response.status == 200) {
            let responseData = JSON.parse(response.data.body.message);
            if (responseData !== null && responseData !== undefined) {
              this.assignedUserItems = [];
              this.assignedUserItems = responseData;
              this.showUserListBtn = true;
            } else {
              this.assignedUserItems = [];
              this.showUserListBtn = false;
            }
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          } else {
            LoaderDialog.visible = false;
            this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
            var Alert = {
              type: "error",
              isShow: true,
              message: response.data.message,
            };
            this.$store.commit("ConfigModule/Alert", Alert);
          }
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
        })
        .catch((error) => {
          LoaderDialog.visible = false;
          this.$store.commit("ConfigModule/LoaderDialog", LoaderDialog);
          var Alert = {
            type: "error",
            isShow: true,
            message: Utility.apiError(error),
          };
          this.$store.commit("ConfigModule/Alert", Alert);
          this.errorMessage = error.message;
        });
    },
    //Assigned User popup close event
    closeDialog() {
      this.assignedUserItems = [];
      this.assignedUsersListDialog = false;
    },
  },
  components: {
    breadcrumbComp,
    BackToTop,
  },
};
